import React, { ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import {
  Switch,
  Redirect,
  RouteProps,
  Route,
  useLocation,
} from 'react-router-dom';
import { AuthContext } from 'contexts';
import AuthorizationGuard from 'components/AuthorizationGuard';
import { Sidebar } from 'components/layout/Sidebar';
import { Navbar } from 'components/layout/Navbar';
import { URL_INTEGRATIONS, URL_INTEGRATION_LIST } from '@constants/integration';
import { translate } from 'utils/translations';

const NewProjectForm = lazy(() => import('views/newproject/NewProjectForm'));
const NewProjectWorkflow = lazy(
  () => import('views/newproject/NewProjectWorkflow'),
);
const NewProject = lazy(() => import('views/newproject/NewProject'));
const ProjectsPage = lazy(() => import('views/projects/ProjectsPage'));
const RepositoryPage = lazy(() => import('views/repository/RepositoryPage'));
const ReportsPage = lazy(() => import('views/reports'));
const ImportsPage = lazy(() => import('views/imports/Imports'));
const ReportsLibraryPage = lazy(() => import('views/reportsLibrary'));
const Library = lazy(() => import('views/Library'));
const SuppliersPage = lazy(() => import('views/suppliers/SuppliersPage'));
const TeamPage = lazy(() => import('views/team/TeamPage'));
const IntegrationsPage = lazy(() => import('views/integration/Integrations'));
const NotificationsPage = lazy(
  () => import('views/notifications/NotificationsPage'),
);
const AiPage = lazy(() => import('views/ai/AiPage'));
const Integration = lazy(() => import('views/integration'));
const NewsPage = lazy(() => import('views/news/NewsPage'));
const DesignSystem = lazy(() => import('views/ui/DesignSystem'));
const ProjectDetail = lazy(() => import('views/projects/ProjectDetail'));
const ClauseLibrary = lazy(() => import('views/ClauseLibrary'));
import NavbarContext, { NavbarContextProps } from 'contexts/NavbarContext';
import { ErrorGuard } from 'views/ErrorGuard';

function Shell() {
  return (
    <AuthorizationGuard>
      <ErrorGuard>
        <NavbarProvider>
          <div className="fixed w-full h-full flex">
            <Sidebar />
            <div className="flex flex-col flex-auto" style={{ minWidth: 0 }}>
              <div className="h-20 shrink-0 bg-white border-b">
                <Navbar />
              </div>

              <main className="h-full overflow-y-auto">
                <Switch>
                  <PrivateRoute path="/projects/new/form" title="New Project">
                    <NewProjectForm />
                  </PrivateRoute>

                  <PrivateRoute
                    path="/projects/new/workflow"
                    title="New Project"
                  >
                    <NewProjectWorkflow />
                  </PrivateRoute>

                  <PrivateRoute path="/projects/new" title="New Project">
                    <NewProject />
                  </PrivateRoute>

                  <PrivateRoute exact path="/projects" title="Projects">
                    <ProjectsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/repository" title="Repository">
                    <RepositoryPage />
                  </PrivateRoute>

                  <PrivateRoute path="/reports" title="Reports">
                    <ReportsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/imports" title="Imports">
                    <ImportsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/reports-library" title="Reports Library">
                    <ReportsLibraryPage />
                  </PrivateRoute>

                  <PrivateRoute
                    path="/projects/filters"
                    title="Projects Filters"
                  >
                    <ProjectsPage />
                  </PrivateRoute>

                  <PrivateRoute
                    path="/projects/categories"
                    title="Projects Categories"
                  >
                    <ProjectsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/projects/profile" title="Profile">
                    <ProjectsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/library" title="Library">
                    <Library />
                  </PrivateRoute>

                  <PrivateRoute path="/clauses" title="Clauses">
                    <ClauseLibrary />
                  </PrivateRoute>

                  <PrivateRoute path="/projects/:id">
                    <ProjectDetail />
                  </PrivateRoute>

                  <PrivateRoute path="/suppliers" title="Suppliers">
                    <SuppliersPage />
                  </PrivateRoute>

                  <PrivateRoute path="/team" title="Team">
                    <TeamPage />
                  </PrivateRoute>

                  <PrivateRoute path="/news" title="News">
                    <NewsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/ai" title="AI">
                    <AiPage />
                  </PrivateRoute>

                  <PrivateRoute path={URL_INTEGRATIONS} title="Settings">
                    <Integration />
                  </PrivateRoute>

                  <PrivateRoute
                    path={URL_INTEGRATION_LIST}
                    title="Integrations"
                  >
                    <IntegrationsPage />
                  </PrivateRoute>

                  <PrivateRoute path="/notifications" title="Notifications">
                    <NotificationsPage />
                  </PrivateRoute>

                  {/* ui lib should be accessible in dev environment
                            and CI as some cypress test run against it */}
                  <PrivateRoute path="/ui" title="Design System">
                    <DesignSystem />
                  </PrivateRoute>

                  <PrivateRoute path="*">
                    <Redirect to="/projects" />
                  </PrivateRoute>
                </Switch>
              </main>
            </div>
          </div>
        </NavbarProvider>
      </ErrorGuard>
    </AuthorizationGuard>
  );
}

interface PrivateRouteProps extends RouteProps {
  title?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (
  props: PrivateRouteProps,
) => {
  const { loggedIn } = React.useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (props.title && typeof props.title === 'string') {
      document.title = translate(props.title) + ' | Cequence';
    }
  }, [props?.title]);

  return loggedIn ? (
    <Suspense fallback={<></>}>
      <Route {...props} />
    </Suspense>
  ) : (
    <Redirect to={`/login?redirectTo=${location.pathname}`} />
  );
};

interface NavbarProviderProps {
  children: ReactNode;
}

const NavbarProvider: React.FC<NavbarProviderProps> = ({ children }) => {
  const [renderItem, setRenderItem] = useState<any>(null);

  const contextValue: NavbarContextProps = {
    renderItem,
    setRenderItem,
  };

  return (
    <NavbarContext.Provider value={contextValue}>
      {children}
    </NavbarContext.Provider>
  );
};

export default Shell;
