import { Button } from 'components/Button';
import { translate } from 'utils/translations';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { GuardContext } from 'contexts';
import { useContext } from 'react';

export const ErrorGuard = ({ children }) => {
  const history = useHistory();
  const { user } = useContext(GuardContext);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorPageContent error={error} resetErrorBoundary={resetError} />
      )}
      onReset={() => history.push('/projects')}
      beforeCapture={(scope) => {
        scope.setTag('appVersion', localStorage.getItem('_app_version') || 'unknown');
		if(user){
			scope.setUser({ email: user.email, username: user.name, id: user.id });
		}
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const ErrorPageContent = ({ error, resetErrorBoundary }) => {
  return (
    <div className="error-page flex justify-center items-center space-y-5 flex-col h-full">
      <h1 className="text-xl font-semibold">{translate('An error occured')}</h1>
      <div className="text-center">
        <p>{translate('We apologize for the inconvenience')}.</p>
        <p>
          {translate(
            'Our engineers are aware of this issue and are working to resolve it promptly',
          )}
          .
        </p>
      </div>

      <Button icon="arrow_forward" onClick={() => resetErrorBoundary()}>
        {translate('Continue to dashboard')}
      </Button>
    </div>
  );
};
